import { useEffect, useRef, useState } from "react";
import { useSearchParams } from "react-router-dom";

import { ProfileViewDetailed } from "@atproto/api/dist/client/types/app/bsky/actor/defs";

import { ActivityAnalysisResult } from "../model/activity-analysis-result";
import { AnalysisProgress } from "../model/analysis-progress";
import { Sentry } from "../sentry";

interface WorkerMessage {
	type: string;
	data?: ActivityAnalysisResult | AnalysisProgress;
	error?: string;
}

export function useActivityAnalysis(profile: ProfileViewDetailed | undefined) {
	const [data, setData] = useState<ActivityAnalysisResult>();
	const [isLoading, setIsLoading] = useState(true);
	const [progress, setProgress] = useState<AnalysisProgress>();
	const [error, setError] = useState<Error>();
	const workerRef = useRef<Worker>();

	const [qs] = useSearchParams();
	const nocache = qs.get("nocache");

	useEffect(() => {
		//console.log("P", profile);
		if (!profile) return;

		workerRef.current = new Worker(
			new URL("../workers/activity.worker.ts", import.meta.url),
			{
				type: "module",
			},
		);

		workerRef.current.onmessage = async (event) => {
			const { type, data, error } = event.data as WorkerMessage;

			if (type === "RESULT") {
				setData(data as ActivityAnalysisResult | undefined);
				setError(error ? new Error(error) : undefined);

				if (error) {
					Sentry.captureException(error, {
						extra: {
							profile: profile.handle,
						},
					});
				}

				setIsLoading(false);
			} else if (type === "PROGRESS") {
				setProgress(data as AnalysisProgress);
			}
		};

		workerRef.current.onerror = (error) => {
			setError(error.error);
			setProgress(undefined);
			setIsLoading(false);

			Sentry.captureException(error.error, {
				extra: {
					profile: profile.handle,
				},
			});
		};

		setIsLoading(true);

		workerRef.current.postMessage({
			type: "START",
			data: { profile, nocache },
		});

		return () => {
			//console.log("terminante worker", workerRef.current);
			workerRef.current?.terminate();
			workerRef.current = undefined;
		};
	}, [profile]);

	return {
		data,
		isLoading,
		error,
		progress,
	};
}
