import { createBrowserRouter, RouterProvider } from "react-router-dom";

import { BestTimeToPostPage } from "./pages/BestTimeToPostPage";

const router = createBrowserRouter([
	{
		path: "/:handle?",
		element: <BestTimeToPostPage />,
	},
]);

function App() {
	return <RouterProvider router={router} />;
}

export default App;
