import { Loader2 } from "lucide-react";
import { PropsWithChildren } from "react";

import { Card } from "./ui/card";

export const LoadingMessage = (props: PropsWithChildren) => {
	const { children } = props;

	return (
		<Card className="p-6">
			<div className="pt-2">
				<Loader2 className="h-8 w-8 animate-spin text-primary-500 mx-auto" />
				<div className="flex flex-col items-center gap-2 mt-4">
					<div className="text-sm text-center">{children}</div>
				</div>
			</div>
		</Card>
	);
};
