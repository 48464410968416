import * as Sentry from "@sentry/react";

if (import.meta.env.VITE_SENTRY_DSN) {
	console.log("sentry init");
	Sentry.init({
		dsn: import.meta.env.VITE_SENTRY_DSN,
		environment: import.meta.env.VITE_SENTRY_ENV || "unknown",
		release: import.meta.env.VITE_SENTRY_RELEASE,
		integrations: [],
		normalizeDepth: 10,
	});
}

export { Sentry };
