import {
	AlertTriangle,
	CircleAlert,
	Loader2,
	MessageCircleWarning,
	XCircle,
} from "lucide-react";

import { ProfileViewDetailed } from "@atproto/api/dist/client/types/app/bsky/actor/defs";

import { config } from "../config";
import { useActivityAnalysis } from "../hooks/useActivityAnalysis";
import { useProfileData } from "../hooks/useProfileData";
import { useWindowSize } from "../hooks/useWindowSize";

import { DailyActivityChart } from "./charts/DailyActivityChart";
import { WeeklyActivityChart } from "./charts/WeeklyActivityChart";
import { Alert, AlertDescription } from "./ui/alert";
import {
	Card,
	CardContent,
	CardDescription,
	CardHeader,
	CardTitle,
} from "./ui/card";
import { Separator } from "./ui/separator";

import { ErrorMessage } from "./ErrorMessage";
import { LoadingMessage } from "./LoadingMessage";

export interface FollowerActivityAnalysis {
	profile: ProfileViewDetailed;
}

export const FollowerActivityAnalysis = (props: FollowerActivityAnalysis) => {
	const { profile } = props;

	const { isMobile } = useWindowSize();

	const {
		data: activityAnalysis,
		isLoading,
		error,
		progress,
	} = useActivityAnalysis(profile);

	if (isLoading) {
		return (
			<LoadingMessage>
				{progress && progress.stage === "FETCH_FOLLOWERS" && (
					<>
						Fetching followers: {progress.fetchedFollowerCount}/
						{Math.min(profile.followersCount || 0, config.followerCountLimit)}
					</>
				)}
				{progress && progress.stage === "ANALYZE_FOLLOWERS" && (
					<>
						Analyzing followers activity: {progress.analyzedFollowersCount}/
						{progress.followerSampleSize}
						<div className="opacity-75">
							{progress.analyzedLikesCount} like records found
						</div>
					</>
				)}
			</LoadingMessage>
		);
	}

	if (error || !activityAnalysis) {
		return (
			<ErrorMessage>
				{error?.message === "TOO_MANY_FAILED_REQUESTS" ? (
					<>
						<span className="font-semibold">Analysis failed!</span>
						<br />
						<span className="font-semibold">
							You've probably exceeded the Bluesky API rate limit quota.
						</span>
						<br />
						<span>Please try again later after 2-5 minutes.</span>
					</>
				) : (
					<>
						<span className="font-semibold">
							{error?.message || "Unexpected error"}
						</span>
						<br />
						<span>Please try again later.</span>
					</>
				)}
			</ErrorMessage>
		);
	}

	return (
		<div className="space-y-6 mt-6">
			<Card>
				<CardHeader>
					<CardTitle>Daily Follower Activity</CardTitle>
					<CardDescription>
						Follower activity levels throughout the day
					</CardDescription>
				</CardHeader>
				<CardContent>
					<DailyActivityChart
						activityHeatmap={activityAnalysis.heatmap}
						layout={isMobile ? "vertical" : "horizontal"}
					/>
				</CardContent>
			</Card>
			<Card>
				<CardHeader>
					<CardTitle>Weekly Follower Activity</CardTitle>
					<CardDescription>
						Follower activity patterns throughout the week
					</CardDescription>
				</CardHeader>
				<CardContent>
					<WeeklyActivityChart
						activityHeatmap={activityAnalysis.heatmap}
						isMobile={isMobile}
					/>
				</CardContent>
			</Card>
			{/*<Card>
				<CardHeader>
					<CardTitle>Recommendations</CardTitle>
				</CardHeader>
				<CardContent>
					<div className="space-y-2">
						<div className="flex items-center gap-2">
							<div className="w-3 h-3 rounded-full bg-green-500"></div>
							<span className="text-sm text-gray-600">
								Best time to post: 9:00 - 11:00
							</span>
						</div>
						<Separator className="my-2" />
						<div className="flex items-center gap-2">
							<div className="w-3 h-3 rounded-full bg-yellow-500"></div>
							<span className="text-sm text-gray-600">
								Good time to post: 14:00 - 16:00
							</span>
						</div>
						<Separator className="my-2" />
						<div className="flex items-center gap-2">
							<div className="w-3 h-3 rounded-full bg-red-500"></div>
							<span className="text-sm text-gray-600">
								Avoid posting: 2:00 - 4:00
							</span>
						</div>
					</div>
				</CardContent>
			</Card>*/}
			<Card>
				<CardHeader>
					<CardTitle>How does it work?</CardTitle>
				</CardHeader>
				<CardContent>
					<p className="text-sm text-gray-500 text-justify">
						The analysis is based on follower activity patterns, using a
						statistically significant sample . For each follower, their recent
						likes from the past <span className="font-bold">7 days</span> were
						analyzed to create individual activity profiles, normalized by their
						engagement level, and then aggregated into a comprehensive hourly
						pattern. Results have a ±5% margin of error with 95% confidence
						level.
					</p>
					<p className="text-sm text-gray-500 mt-4">
						For this analysis, a sample of{" "}
						<span className="font-bold">
							{activityAnalysis.analyzedFollowersCount} followers
						</span>{" "}
						was used and a total of{" "}
						<span className="font-bold">
							{activityAnalysis.analyzedLikesCount} likes
						</span>{" "}
						were analyzed.
					</p>
					<Separator className="my-4" />
					<p className="text-sm text-amber-500 mt-4 italic text-justify">
						<CircleAlert className="inline size-[18px]" /> Please note these
						results are based on limited amount of historical data and
						statistical sampling, which may not perfectly predict future
						engagement patterns. Use them as general guidance rather than
						absolute rules, and combine with your own content strategy and
						audience understanding.
					</p>
				</CardContent>
			</Card>
		</div>
	);
};
