import { ExternalLink, Loader2, XCircle } from "lucide-react";

import { ProfileViewDetailed } from "@atproto/api/dist/client/types/app/bsky/actor/defs";

import { Card } from "./ui/card";

import { ErrorMessage } from "./ErrorMessage";
import { LoadingMessage } from "./LoadingMessage";

export interface ProfileInfoProps {
	profile?: ProfileViewDetailed;
	loading?: boolean;
	error?: Error;
}

export const ProfileInfo = (props: ProfileInfoProps) => {
	const { profile, loading, error } = props;

	if (loading) {
		return <LoadingMessage>Loading profile</LoadingMessage>;
	}

	if (error || !profile) {
		return (
			<ErrorMessage>
				<span className="font-semibold">
					{error?.message || "Unexpected error"}
				</span>
				<br />
				<span>Please try again later</span>
			</ErrorMessage>
		);
	}

	return (
		<Card className="p-6">
			<div className="flex flex-col md:flex-row justify-between items-center gap-6">
				<a
					href={`https://bsky.app/profile/${profile.handle}`}
					className="flex items-center gap-2"
					target="_blank"
					rel="noreferrer"
				>
					<img
						src={profile?.avatar}
						alt={profile?.displayName || profile.handle}
						className="w-12 h-12 rounded-full"
					/>
					<div>
						{profile.displayName && (
							<div className="font-semibold">{profile.displayName}</div>
						)}
						<div className="text-sm text-muted-foreground flex flex-row items-center gap-2">
							@{profile.handle} <ExternalLink size={14} />
						</div>
					</div>
				</a>
				<div className="flex gap-6 text-sm">
					<div className="text-center">
						<div className="font-semibold">
							{profile?.followersCount?.toLocaleString()}
						</div>
						<div className="text-muted-foreground">Followers</div>
					</div>
					<div className="text-center">
						<div className="font-semibold">
							{profile?.followsCount?.toLocaleString()}
						</div>
						<div className="text-muted-foreground">Following</div>
					</div>
					<div className="text-center">
						<div className="font-semibold">
							{profile?.postsCount?.toLocaleString()}
						</div>
						<div className="text-muted-foreground">Posts</div>
					</div>
				</div>
			</div>
		</Card>
	);
};
