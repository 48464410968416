import { XCircle } from "lucide-react";
import { PropsWithChildren, ReactNode } from "react";

import { Card } from "./ui/card";

export const ErrorMessage = (props: PropsWithChildren) => {
	const { children } = props;

	return (
		<Card className="flex flex-col items-center justify-center gap-4 p-8 text-red-700">
			<XCircle className="h-8 w-8 text-destructive" />
			<p className="text-sm text-center">{children}</p>
		</Card>
	);
};
