import clsx from "clsx";
import {
	AlertTriangle,
	BarChart,
	Calendar,
	ChevronLeft,
	Sparkles,
	Sun,
} from "lucide-react";
import { useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";

import BlueskyLogo from "@/assets/bluesky-logo.svg";
import Logo from "@/assets/logo.svg";
import { Button } from "@/components/ui/button";
import { Card } from "@/components/ui/card";
import { Input } from "@/components/ui/input";

import { FollowerActivityAnalysis } from "../components/FollowerActivityAnalysis";
import { ProfileInfo } from "../components/ProfileInfo";
import { Badge } from "../components/ui/badge";
import { config } from "../config";
import { useProfileData } from "../hooks/useProfileData";

export function BestTimeToPostPage() {
	const navigate = useNavigate();
	const { handle } = useParams();
	const [handleInputValue, setHandleInputValue] = useState(handle || "");
	const [analysisKey, setAnalysisKey] = useState(0);

	const { profile, loading, error } = useProfileData(handle);

	const handleAnalyze = () => {
		if (!handleInputValue) return;

		if (handleInputValue === handle) {
			setAnalysisKey((prev) => prev + 1);
		} else {
			navigate(`/${handleInputValue}`);
		}
	};

	return (
		<div className="container mx-auto p-4">
			<div className="mx-auto max-w-3xl">
				<div className={clsx("text-left", !handle && "invisible")}>
					<Button variant="ghost" asChild>
						<Link to="/">
							<ChevronLeft />
							New analysis
						</Link>
					</Button>
				</div>
				<header className="mt-8">
					<img src={Logo} className="block mx-auto size-[48px] mb-6" />
					<h1 className="text-3xl font-bold sm:text-center">
						Best Time to Post to{" "}
						<span className="inline-flex flex-row items-center gap-2">
							<span className="text-blue-500">Bluesky</span>
							<img
								src={BlueskyLogo}
								alt="Bluesky Logo"
								className="h-[20px] relative top-[-2px] left-[-2px]"
							/>
						</span>
					</h1>
					<p className="sm:text-center mt-4 text-md text-neutral-500">
						Find the optimal time to post based on your followers' activity
					</p>
				</header>
				<main className="mt-8 flex-1">
					{!handle && (
						<>
							<div className="flex flex-col sm:flex-row items-center justify-center gap-4 mb-4">
								<div className="w-full sm:w-[300px]">
									<Input
										className="bg-white"
										placeholder="example.bsky.social"
										value={handleInputValue}
										onChange={(e) => setHandleInputValue(e.target.value)}
										onKeyDown={(e) => {
											if (e.key === "Enter") {
												handleAnalyze();
											}
										}}
									/>
								</div>
								<Button
									variant="default"
									onClick={handleAnalyze}
									disabled={!handleInputValue}
									className="w-full sm:w-auto"
								>
									Analyze
								</Button>
							</div>

							<div className="flex justify-center mt-8 gap-4">
								<Badge
									variant="secondary"
									className="py-1 px-3 bg-blue-50 text-blue-600 hover:bg-blue-50 transition-colors flex items-center gap-2 cursor-default"
								>
									<Sparkles className="h-3.5 w-3.5" />
									100% FREE!
								</Badge>
							</div>

							<div className="mt-12 max-w-sm mx-auto">
								<div className="space-y-6">
									<div className="flex items-start gap-3">
										<div className="h-6 w-6 rounded-full bg-blue-100 flex items-center justify-center text-blue-600 mt-0.5 shrink-0">
											<Sun className="h-3.5 w-3.5" />
										</div>
										<div>
											<div className="font-medium">Stop posting blindly</div>
											<div className="text-sm text-neutral-500">
												Understanding when your audience is most engaged, is
												crucial to increase your chances of reaching them and
												catch their attention.
											</div>
										</div>
									</div>
									<div className="flex items-start gap-3">
										<div className="h-6 w-6 rounded-full bg-blue-100 flex items-center justify-center text-blue-600 mt-0.5 shrink-0">
											<BarChart className="h-3.5 w-3.5" />
										</div>
										<div>
											<div className="font-medium">Daily activity insight</div>
											<div className="text-sm text-neutral-500">
												See when your followers are active throughout the day.
												Use this data to identify the best posting times for
												maximum visibility and engagement.
											</div>
										</div>
									</div>
									<div className="flex items-start gap-3">
										<div className="h-6 w-6 rounded-full bg-blue-100 flex items-center justify-center text-blue-600 mt-0.5 shrink-0">
											<Calendar className="h-3.5 w-3.5" />
										</div>
										<div>
											<div className="font-medium">
												Weekly engagement patterns
											</div>
											<div className="text-sm text-neutral-500">
												Visualize engagement patterns across the week, which
												allows you to refine your content strategy and
												consistently connect with your audience.
											</div>
										</div>
									</div>
								</div>
							</div>
						</>
					)}

					{handle && (
						<div className="mt-10 space-y-6">
							<ProfileInfo profile={profile} loading={loading} error={error} />
							{profile?.followersCount &&
								profile?.followersCount > config.followerCountLimit && (
									<Card className="px-5 py-3 flex flex-row items-center gap-2 bg-yellow-50 text-yellow-700 border-yellow-700 shadow-none text-sm">
										<AlertTriangle className="size-4" />
										<p>
											Only the recent{" "}
											{new Intl.NumberFormat("en-US", { notation: "compact" })
												.format(config.followerCountLimit)
												.toLowerCase()}{" "}
											followers are considered for this analysis
										</p>
									</Card>
								)}
							{profile && (
								<FollowerActivityAnalysis key={analysisKey} profile={profile} />
							)}
						</div>
					)}
				</main>
			</div>
			<footer className="text-center text-sm text-gray-500 mt-24 mb-12">
				<div>
					Made by{" "}
					<a
						href="https://bsky.app/profile/notemation.com"
						target="_blank"
						rel="noreferrer"
						className="inline-flex items-center text-blue-500 hover:text-blue-600"
					>
						@notemation.com
					</a>
				</div>
				<div className="mt-4">
					<Button variant="outline" size="sm" asChild>
						<a
							href="https://notemation.gumroad.com/l/support-me?referrer=http%3A%2F%2Fbesttimetopost.blue"
							target="_blank"
							rel="noreferrer"
						>
							Support me 🤍
						</a>
					</Button>
				</div>
			</footer>
		</div>
	);
}
