import * as React from "react";
import { useMemo } from "react";
import {
	Bar,
	BarChart,
	CartesianGrid,
	XAxis,
	XAxisProps,
	YAxis,
} from "recharts";
import { BaseAxisProps } from "recharts/types/util/types";

import { ChartConfig, ChartContainer } from "@/components/ui/chart";

import { days } from "../../model/days";
import { ActivityHeatmap } from "../../model/likes-heatmap";

interface ChartDataItem {
	hour: number;
	activity: number;
}

interface DailyActivityChartProps {
	activityHeatmap: ActivityHeatmap;
	layout?: "horizontal" | "vertical";
}

const chartConfig = {
	activity: {
		label: "Activity Level",
		color: "hsl(210,99%,58%)", //"#2185ff",
	},
} satisfies ChartConfig;

export function DailyActivityChart(props: DailyActivityChartProps) {
	const { activityHeatmap, layout = "vertical" } = props;

	const chartData = useMemo(() => {
		const chartData = [];

		for (let hour = 0; hour < 24; ++hour) {
			const activityRates = days.map((day) => activityHeatmap[day]![hour]!);
			const avgActivityRate =
				activityRates.reduce((sum, it) => sum + it, 0) / activityRates.length;

			chartData.push({
				hour,
				activity: avgActivityRate * 100,
			} satisfies ChartDataItem);
		}

		return chartData;
	}, [activityHeatmap]);

	const hourAxisProps: BaseAxisProps & Pick<XAxisProps, "interval"> = {
		dataKey: "hour",
		type: "category",
		interval: 0,
	};

	const activityAxisProps: BaseAxisProps = {
		dataKey: "activity",
		type: "number",
		tickFormatter: (value) => value.toFixed(0) + "%",
	};

	return (
		<div>
			<ChartContainer config={chartConfig} className="min-h-[640px] w-full">
				<BarChart
					accessibilityLayer
					data={chartData}
					layout={layout}
					margin={
						layout === "vertical"
							? { left: -20, bottom: 5, right: 5, top: 5 }
							: { left: -10, bottom: 5, right: 0, top: 5 }
					}
				>
					<XAxis
						{...(layout === "vertical" ? activityAxisProps : hourAxisProps)}
						tickLine={false}
						tickMargin={10}
						axisLine={false}
						allowDecimals={false}
					/>
					<YAxis
						{...(layout === "vertical" ? hourAxisProps : activityAxisProps)}
						tickLine={false}
						tickMargin={10}
						axisLine={false}
						allowDecimals={false}
					/>
					<CartesianGrid
						vertical={layout === "vertical"}
						horizontal={layout !== "vertical"}
						strokeWidth={0.5}
					/>
					<Bar
						dataKey="activity"
						fill="var(--color-activity)"
						radius={layout === "vertical" ? [0, 4, 4, 0] : [4, 4, 0, 0]}
					/>
				</BarChart>
			</ChartContainer>
			<div className="flex flex-row mt-4">
				<div className="ms-auto text-[12px] opacity-65">
					Times are in {Intl.DateTimeFormat().resolvedOptions().timeZone}{" "}
					timezone.
				</div>
			</div>
		</div>
	);
}
