import { useEffect, useState } from "react";

import { AtpAgent } from "@atproto/api";
import { ProfileViewDetailed } from "@atproto/api/dist/client/types/app/bsky/actor/defs";

interface UseProfileDataReturn {
	profile?: ProfileViewDetailed;
	loading: boolean;
	error?: Error;
	did?: string;
}

export function useProfileData(
	handle: string | undefined,
): UseProfileDataReturn {
	const [profile, setProfile] = useState<ProfileViewDetailed>();
	const [loading, setLoading] = useState(true);
	const [error, setError] = useState<Error>();

	useEffect(() => {
		async function fetchProfile() {
			if (!handle) return;

			setLoading(true);
			setError(undefined);
			setProfile(undefined);

			try {
				const publicAgent = new AtpAgent({
					service: "https://public.api.bsky.app",
				});

				//console.log("Resolving handle:", handle);
				const {
					data: { did: resolvedDid },
				} = await publicAgent.resolveHandle({ handle });
				//console.log("Resolved DID:", resolvedDid);

				// Fetch profile data
				const { data: profileData } =
					await publicAgent.app.bsky.actor.getProfile({
						actor: resolvedDid,
					});

				//console.log("Resolved profile:", profileData);
				setProfile(profileData);
			} catch (error: any) {
				console.error("Error fetching profile:", error);
				setError(error);
			} finally {
				setLoading(false);
			}
		}

		fetchProfile();
	}, [handle]);

	return {
		profile,
		loading,
		error,
	};
}
