import clsx from "clsx";
import * as React from "react";

import { Day, days } from "../../model/days";
import { ActivityHeatmap } from "../../model/likes-heatmap";

interface WeeklyActivityChartProps {
	activityHeatmap: ActivityHeatmap;
	isMobile: boolean;
}

const getCellColor = (activity: number, step: number) => {
	if (activity < step) return "bg-blue-50";
	if (activity < 2 * step) return "bg-blue-100";
	if (activity < 3 * step) return "bg-blue-200";
	if (activity < 4 * step) return "bg-blue-300";
	return "bg-blue-400";
};

const hours = Array.from({ length: 24 }, (_, i) => i);

export function WeeklyActivityChart(props: WeeklyActivityChartProps) {
	const { activityHeatmap, isMobile } = props;

	const primaryLabels = isMobile ? days : hours;
	const secondaryLabels = isMobile ? hours : days;

	const maxActivity = Math.max(
		...Object.values(activityHeatmap).flatMap((it) => it),
	);

	return (
		<div>
			<div className="overflow-x-auto">
				<div
					className={clsx(
						"grid gap-1 auto-rows-auto",
						isMobile
							? "grid-cols-[auto,repeat(7,1fr)]"
							: "grid-cols-[auto,repeat(24,1fr)]",
					)}
				>
					{secondaryLabels.map((secondaryLabel) => (
						<React.Fragment key={secondaryLabel}>
							<div className="text-[12px] text-gray-600 pr-2 py-[2px] flex items-center whitespace-nowrap">
								{secondaryLabel}
							</div>
							{primaryLabels.map((primaryLabel) => {
								const activity =
									activityHeatmap[
										(isMobile ? primaryLabel : secondaryLabel) as Day
									][(isMobile ? secondaryLabel : primaryLabel) as number]!;
								return (
									<div
										key={`${secondaryLabel}-${primaryLabel}`}
										className={clsx(
											"rounded",
											getCellColor(activity || 0, maxActivity / 5),
										)}
									>
										{/*(activity * 100).toFixed(1) + "%"*/}
									</div>
								);
							})}
						</React.Fragment>
					))}
					<div className="row-start-1 md:row-start-auto" />
					{primaryLabels.map((label) => (
						<div
							key={label}
							className="text-gray-500 text-center whitespace-nowrap py-2 row-start-1 md:row-start-auto text-[12px] md:pb-0"
						>
							{label}
						</div>
					))}
				</div>
			</div>
			<div className="mt-6 flex flex-col sm:flex-row md:items-center gap-4 items-end">
				<div className="flex flex-row items-center gap-2">
					<span className="text-sm text-gray-500">Low</span>
					<div className="flex items-center gap-1">
						{[0, 1, 2, 3, 4].map((it) => (
							<div
								key={it}
								className={clsx("w-4 h-4 rounded", getCellColor(it, 1))}
							></div>
						))}
					</div>
					<span className="text-sm text-gray-500">High</span>
				</div>
				<div className="sm:ms-auto text-[12px] opacity-65">
					Times are in {Intl.DateTimeFormat().resolvedOptions().timeZone}{" "}
					timezone.
				</div>
			</div>
		</div>
	);
}
